import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import logoFoodsby from '../../images/logo-foodsby.png'
import logoFoodsbySm from '../../images/logo-foodsby-sm.png'
import styled from 'styled-components'
import useOutsideClick from '../utilities/click'
import { Button } from './Button'
import { Icon } from './Icon'
import { space } from 'styled-system'
import { themeConWeb } from '../themes'

const styles = themeConWeb

const Component = styled.nav`
  ${space}
  backdrop-filter: blur(10px);
  background-color: ${styles.color.white};
  box-sizing: border-box;
  color: ${styles.color.white};
  display: flex;
  font-family: ${styles.fonts.primary} !important;
  justify-content: center;
  width: 100%;
  z-index: 4;

  & .-container {
    align-items: center;
    display: flex;
    flex: 1 0 auto;
    height: calc(${styles.baseUnit} * 20);
    max-width: 828px;
    padding: ${styles.baseUnit} calc(${styles.baseUnit} * 4);
    width: 100%;
  }

  & .-logos { flex: 1;}
  & .-logoSm { 
    display: none;
    max-width: 35px; 
  }

  & .-logoLg { max-width: 104px}

  & .-products {
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: calc(${styles.baseUnit} * -2) ;
    text-align: center;
    width: 140px;
  }

  & .-product {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  & .-productLabel {
    color: #68728c;
    font-weight: bold;
  }
  
  & .-single,
  & .-group {
    align-items: center;
    border-radius: 50%;
    background-color: ${styles.color.grey};
    color: #68728c;
    display: flex;
    height: calc(${styles.baseUnit} * 10);
    justify-content: center;  
    margin-bottom: ${styles.baseUnit};
    transition: background-color .4s;
    width: calc(${styles.baseUnit} * 10);

    &:hover {
      border: 1px solid #008d99;
    }

    &:hover::after {
      background-color: ${styles.color.greyShade2};
    }

    &::after { 
      bottom: -6px;
      content: '';
      display: block;
      height: 2px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      width: calc(${styles.baseUnit} * 6);
      z-index: 2;
    }
  }

  & .-single::before { font-size: ${styles.fontSizes.base}; }
  
  /* Icons are visually sized the same */
  & .-group::before { font-size: 26px; }
  
  /* Height and with need to match .-flyoutClose, we can fix this centering their absolute positioning  */
  & .-flyout {
    display: flex;
    flex: 1;
    height: calc(${styles.baseUnit} * 10);
    justify-content: flex-end; 
    position: relative;
    width: calc(${styles.baseUnit} * 10);
    z-index: 1;

    &::before {
      color: #008d99
      content: '^';
      display: block;
      font-family: ${styles.fonts.monospace};
      font-size: 11px;
      position: absolute;
      right: -2px;
      top: calc(${styles.baseUnit} * 2);
      transform: rotate(180deg);
      transform-origin: right;
    }
  }

  & .-flyoutControls {
    cursor: pointer;
    position: relative;
    transform: rotate(0deg);
    transition: all .5s ease;
  }

  /* hand rolled hamburger nav */
  & .-flayoutHamburger::before {
      border: 1px solid #e9ebee;
      border-radius: 50%;
      color:#525e7b;
      font-size: ${styles.fontSizes.basePlus2};
      opacity: 1;
      position: absolute;
      transition: all .3s;
      visibility: visible;
      padding: calc(${styles.baseUnit} * 1.5);
    }
  }

  & .-flayoutHamburger:hover::before { 
    border: 1px solid #008d99;
    z-index: 1;
  }

  /* Height and with need to match .-flyout, we can fix this centering their absolute positioning  */
  & .-flyoutClose {
    align-items: center;
    display: flex;
    height: calc(${styles.baseUnit} * 10); 
    justify-content: center;
    width: calc(${styles.baseUnit} * 10);
  }

  & .-flyoutClose::before,
  & .-flyoutClose::after {
    background-color: #d3d6de;
    content: '';
    display: block;
    height: ${styles.baseUnit};
    opacity: 0;
    position: absolute;
    transition: opacity .4s;
    width:  calc(${styles.baseUnit} * 6);
  }

  & .-flyoutClose::before { transform: translateY(0) rotate(-45deg); }

  & .-flyoutClose::after { transform: translateY(0) rotate(45deg); }

  & .-flyoutDrawer {
    background-color: ${styles.color.white};
    box-shadow: ${styles.elevation2};
    color: ${styles.primaryType};
    margin: calc(${styles.baseUnit} * 4) calc(${styles.baseUnit} * -4) 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 100%;
    transition: 0.25s;
    visibility: hidden;
    width: 216px;

    &::before {
      background: ${styles.color.white};
      content: '';
      display: block;
      height: calc(${styles.baseUnit} * 4);
      position: absolute;
      right: calc(${styles.baseUnit} * 6);
      top: calc(${styles.baseUnit} * -1);
      transform: rotate(45deg);
      width: calc(${styles.baseUnit} * 4);
      z-index: 0;
    }
    &hover:&::before {
      border: 1px solid #008d99;
    }
  }

  & .-flyoutItem {
    align-items: center;
    align-items: stretch;
    display: flex;
    font-size: 16px;
    height: calc(${styles.baseUnit} * 12);
    position: relative;
    
    &:not(:last-child) {
      border-bottom: 1px solid ${styles.color.greyShade2};
    }
  }

  & .-flyoutLink {
    align-items: center;
    color: ${styles.primaryType};
    display: flex;
    flex-grow: 1;
    font-family: ${styles.fonts.primary};
    font-size: 16px;
    padding: 0 calc(${styles.baseUnit} * 4);
  }

  /* Sub-flyout */
  & .-flyoutItem .-flyoutDrawer {
    position: absolute;
    right: calc(100% + 28px);
    top: calc(100% - 64px);

    &::before {
      right: calc(4px * -2);
      top: calc(4px * 4);
    }
  }
  &.is-active .-flyoutItem .-flyoutDrawer {
    visibility: hidden;
    opacity: 0;
  }

  &.is-active .-flyoutItem:hover .-flyoutDrawer {
    visibility: visible;
    opacity: 1;
  }

  /* Login Button */
  & .-login {
    flex: 1;
    text-align: right;
  }

  /* Active states */
  &.is-active {
    & .-flyout::before { display: none; }
    & .-flayoutHamburger::before {
      opacity: 0;
      visibility: visible;
    }

    & .-flyoutClose::before,
    & .-flyoutClose::after {
      opacity: 1;
      visibility: visible;
    }
  
    & .-flyoutControls {
      transform: rotate(90deg);

      &::before {
        opacity: 0;
        visibility: hidden;
      }
    }
    
    & .-flyoutDrawer {
      opacity: 1;
      visibility: visible;
    }
  }

  .is-selected {
    color: #008d99;

    & .-single,
    & .-group  { background-color: #008d99; }

    & .-single::before,
    & .-group::before { color: ${styles.color.white}; }

    & .-single::after,
    & .-group::after { 
      background-color: #008d99;
    }



    & .-productLabel {
      color: #008d99;
    }  
  }

  /* responsive styles */
  @media screen and ${styles.small} {
    & .-logoLg {
      display: none;
    }

    & .-logoSm {
      display: block;
    }
  }

  /* temp hack till we solve issues with the login page */
  &.is-loginHidden .-login{
      visibility: hidden;
  } 
}
`

export const NavBar = ({
  children,
  dailyLink,
  flyoutLinks,
  hideLoginButton,
  home,
  isAuthenticated,
  isDailySelected,
  loginLink,
  loginOnClick,
  meetingsLink,
  ...rest
}) => {
  const [flyoutIsActive, setFlyoutIsActive] = useState(false)
  const ref = useRef()

  useOutsideClick(ref, () => {
    setFlyoutIsActive(false)
  })

  return (
    <Component
      className={cn('NavBar', {
        'is-active': flyoutIsActive,
        'is-loginHidden': hideLoginButton
      })}
      {...rest}
    >
      <div className="-container">
        <div className="-logos">
          <a className="-logos" href={home}>
            <img alt="Foodsby" className="-logoLg" src={logoFoodsby} />
            <img alt="Foodsby" className="-logoSm" src={logoFoodsbySm} />
          </a>
        </div>
        <div className="-products">
          <a
            href={dailyLink}
            className={cn('-product', {
              'is-selected': isDailySelected
            })}
          >
            <Icon className="-single" variant="daily" />
            <span className="-productLabel">for You</span>
          </a>
          <a
            href={meetingsLink}
            className={cn('-product', {
              'is-selected': !isDailySelected
            })}
          >
            <Icon className="-group" variant="meetings" />
            <span className="-productLabel">for Groups</span>
          </a>
        </div>

        {isAuthenticated ? (
          <div className="-flyout">
            <div className="-flyoutControls" ref={ref}>
              <Icon
                className="-flayoutHamburger"
                variant="hamburger"
                onClick={() => setFlyoutIsActive(!flyoutIsActive)}
              />
              <div
                className="-flyoutClose"
                onClick={() => setFlyoutIsActive(!flyoutIsActive)}
              ></div>
            </div>
            <ul
              className="-flyoutDrawer"
              onClick={() => setFlyoutIsActive(!flyoutIsActive)}
            >
              {flyoutLinks.map((link, key) => (
                <li key={key} className="-flyoutItem">
                  <a href={link.href} className="-flyoutLink">
                    {link.copy}
                  </a>
                  {Array.isArray(link.flyoutLinks) && (
                    <ul className="-flyoutDrawer">
                      {link.flyoutLinks.map((link, key) => (
                        <li key={key} className="-flyoutItem">
                          <a href={link.href} className="-flyoutLink">
                            {link.copy}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="-login">
            <Button
              className="secondary"
              href={loginLink}
              onClick={loginOnClick}
            >
              Log In
            </Button>
          </div>
        )}
      </div>
      {children}
    </Component>
  )
}

NavBar.propTypes = {
  children: PropTypes.node,
  dailyLink: PropTypes.string,
  flyoutLinks: PropTypes.arrayOf(PropTypes.object),
  hideLoginButton: PropTypes.bool,
  home: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  isDailySelected: PropTypes.bool,
  loginLink: PropTypes.string,
  loginOnClick: PropTypes.func,
  meetingsLink: PropTypes.string
}
export default NavBar
