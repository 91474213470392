import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { NavBar } from '../js/components/NavBar';
import { ThemeProvider } from 'styled-components';
import { themeConWeb, themeEnterprise, themeGO } from '../js/themes';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "navbar"
    }}>{`NavBar`}</h1>
    <h2 {...{
      "id": "warning"
    }}>{`Warning`}</h2>
    <p>{`This component is being depricated and will be removed from Nutrient in the near future.
Avoid using these components.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={NavBar} mdxType="Props" />
    <h2 {...{
      "id": "playground"
    }}>{`Playground`}</h2>
    <Playground __position={1} __code={'<ThemeProvider theme={themeConWeb}>\n  <NavBar\n    isDailySelected={true}\n    isAuthenticated={true}\n    flyoutLinks={[\n      { href: \'/\', copy: \'Profile\' },\n      { href: \'/\', copy: \'Action\' },\n      { href: \'/\', copy: \'Action\' },\n      {\n        href: \'/\',\n        copy: \'Admin\',\n        flyoutLinks: [\n          { href: \'/\', copy: \'Action\' },\n          { href: \'/\', copy: \'Jackson\' },\n          { href: \'/\', copy: \'Action\' },\n        ],\n      },\n      { href: \'/\', copy: \'Action\' },\n    ]}\n  />\n</ThemeProvider>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      NavBar,
      ThemeProvider,
      themeConWeb,
      themeEnterprise,
      themeGO
    }} mdxType="Playground">
  <ThemeProvider theme={themeConWeb} mdxType="ThemeProvider">
  <NavBar isDailySelected={true} isAuthenticated={true} flyoutLinks={[{
          href: '/',
          copy: 'Profile'
        }, {
          href: '/',
          copy: 'Action'
        }, {
          href: '/',
          copy: 'Action'
        }, {
          href: '/',
          copy: 'Admin',
          flyoutLinks: [{
            href: '/',
            copy: 'Action'
          }, {
            href: '/',
            copy: 'Jackson'
          }, {
            href: '/',
            copy: 'Action'
          }]
        }, {
          href: '/',
          copy: 'Action'
        }]} mdxType="NavBar" />
  </ThemeProvider>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      